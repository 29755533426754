<template>
<div>
  <b-carousel
    ref="carousel"
    v-model="slide"
    :interval="0"
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
  >
    <b-carousel-slide v-for="(slide, index) in slides" :key="index">
      <template #img>
        <img
          class="d-block img-fluid w-100"
          :src="slide"
          @click="onClick"
          />
      </template>
    </b-carousel-slide>
  </b-carousel>
</div>
</template>

<script>
import { getLog } from '@/services/log';
let log = getLog('slide-viewer');

export default {
  props: {
    slides: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      slide: 0,
      moving: false,
    };
  },
  mounted() {
    this.startTime = Date.now();
  },
  methods: {
    onSlideStart(slide) {
      this.moving = true;
      this.$emit('update', {
        slide,
        previousSlide: this.slide,
        previousTime: Date.now() - this.startTime,
      });
    },
    onSlideEnd() {
      this.moving = false;
      this.startTime = Date.now();
    },
    onClick(event) {
      //log.log('click', event);
      if (this.moving) {
        //log.log('still moving, ignoring click');
        return;
      }
      let ratio = event.offsetX / event.srcElement.width;
      let left = ratio < 0.5;
      log.log('click', left ? "left" : "right");
      if (left) {
        if (this.slide > 0)
          this.$refs.carousel.prev();
        else
          log.log('ignoring click');
      } else {
        if (this.slide < this.slides.length - 1)
          this.$refs.carousel.next();
        else
          log.log('ignoring click');
      }
    },
  }
};
</script>
